import React, { useState } from 'react';
import PhotoCard from '../components/photocard';
import '../styling/photography.css'

const photos = [
  {id: 1, src: '/assets/photography/photo-1.gif', alt: '', tag: ['Editorial']},
  {id: 2, src: '/assets/photography/photo-9.png', alt: '', tag: ['Portrait']},
  {id: 3, src: '/assets/photography/photo-15.jpeg', alt: '', tag: ['Portrait']},
  {id: 4, src: '/assets/photography/photo-25.png', alt: '', tag: ['Editorial']},
  {id: 5, src: '/assets/photography/photo-8.JPG', alt: '', tag: ['Event']},
  {id: 6, src: '/assets/photography/photo-28.png', alt: '', tag: ['Portrait', 'Editorial']},
  {id: 7, src: '/assets/photography/photo-5.jpeg', alt: '', tag: ['Editorial']},
  {id: 8, src: '/assets/photography/photo-2.png', alt: '', tag: ['Beauty', 'Editorial']},
  {id: 9, src: '/assets/photography/photo-18.png', alt: '', tag: ['Portrait']},
  {id: 10, src: '/assets/photography/photo-13.png', alt: '', tag: ['Editorial']},
  {id: 11, src: '/assets/photography/photo-6.png', alt: '', tag: ['Beauty', 'Editorial']},
  {id: 12, src: '/assets/photography/photo-29.jpg', alt: '', tag: ['Portrait']},
  {id: 13, src: '/assets/photography/photo-21.png', alt: '', tag: ['Portrait']},
  {id: 14, src: '/assets/photography/photo-10.png', alt: '', tag: ['Portrait']},
  {id: 15, src: '/assets/photography/photo-16.png', alt: '', tag: ['Beauty', 'Editorial']},
  {id: 16, src: '/assets/photography/photo-7.jpg', alt: '', tag: ['Portrait']},
  {id: 17, src: '/assets/photography/photo-14.jpeg', alt: '', tag: ['Portrait', 'Editorial']},
  {id: 18, src: '/assets/photography/photo-19.jpeg', alt: '', tag: ['Portrait']},
  {id: 19, src: '/assets/photography/photo-22.png', alt: '', tag: ['Portrait', 'Editorial']},
  {id: 20, src: '/assets/photography/photo-24.JPG', alt: '', tag: ['Event']},
  {id: 21, src: '/assets/photography/photo-23.png', alt: '', tag: ['Portrait', 'Editorial']},
]

const getUniqueTags = (photos) => {
  const tags = photos.flatMap(photo => photo.tag);
  return [...new Set(tags)];
};

const Photography = () => {
  const [selectedTag, setSelectedTag] = useState('All');
  const uniqueTags = ['All', ...getUniqueTags(photos)];

  const filteredPhotos = selectedTag === 'All' ? photos : photos.filter(photo => photo.tag.includes(selectedTag));

  return (
    <div className='photography-page'>
      <h1>Photography</h1>
      <div className='filter-buttons'>
        {uniqueTags.map(tag => (
          <button 
            key={tag} 
            onClick={() => setSelectedTag(tag)} 
            className={tag === selectedTag ? 'active' : ''}
          >
            {tag}
          </button>
        ))}
      </div>
      <section className='gallery'>
        {filteredPhotos.map(photo => (
            <PhotoCard key={photo.id} src={photo.src} alt={photo.alt} />
        ))}
      </section>
    </div>
  );
}

export default Photography;
