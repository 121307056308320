import React from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faSpotify, faApple, faInstagram, faTiktok, faFacebook } from '@fortawesome/free-brands-svg-icons';
import '../styling/podcastDetails.css';

const podcasts = [
    {
        id: 1, 
        title: 'Proverbial Minds', 
        imageUrl: '/assets/images/proverbial-minds.png',
        description: `Proverbial Minds, a podcast where timeless wisdom meets modern leadership.
            In our podcast, we delve into the age-old treasures of proverbs, drawing from the rich tapestry of biblical teachings, ancient wisdom, and philosophical insights to illuminate the path to success and leadership excellence.
            Join us on a journey to uncover the hidden gems of leadership and success as we explore the timeless adages that transcend time and culture. Whether you're a seasoned leader, executive, aspiring entrepreneur, or striving for greatness in any sphere of life, our discussions offer practical wisdom and actionable insights to help you navigate the complexities of leadership and achieve your goals with integrity and purpose.
            At Proverbial Minds, we're not just building a podcast; we're cultivating a community of inspired individuals committed to personal growth, professional excellence, and making a positive impact in the world. Tune in to Proverbial Minds and unlock the secrets to becoming the leader and individual you were destined to be. Join the conversation, ignite your potential, and let the proverbs guide you to success and leadership mastery.`,
        tags: ['Leadership', 'Spiritual'],
        host: 'Samuel Oladeji',
        backgroundUrl: '/assets/images/proverbial-minds-banner.png',
        socialLinks: {
            youtube: 'https://www.youtube.com/@ProverbialMinds',
            spotify: '',
            apple: '',
            instagram: '',
            tiktok: '',
            facebook: '',
        }
    },
    {
        id: 2, 
        title: 'Ask Aunty Dupe', 
        imageUrl: '/assets/images/ask-aunty-dupe.png',
        description: `Ask Aunty Dupe, a podcast dedicated to exploring the heartbeat of God: mission. 
            Here, we dive deep into what mission truly means, sharing the inspiring lives of missionaries and their families. Join us as we focus on missionary kids, the rewards, benefits, and challenges of missionary work, and their incredible mission experiences. 
            Subscribe to be part of this journey and discover the profound impact of living a life devoted to mission.`,
        tags: ['Mission', 'Spiritual', 'God', 'Service'],
        host: 'Modupe Oluwadoromi',
        backgroundUrl: '/assets/images/ask-aunty-dupe-banner.png',
        socialLinks: {
            youtube: 'https://www.youtube.com/@askauntydupe',
            spotify: '',
            apple: '',
            instagram: '',
            tiktok: '',
            facebook: '',
        }
    }
];

const PodcastDetail = () => {
    // const { id } = useParams();
    // const podcast = podcasts.find(p => p.id === parseInt(id));
    const { title } = useParams();
    const formattedTitle = title.replace(/-/g, ' ');
    const podcast = podcasts.find(p => p.title.toLowerCase() === formattedTitle.toLowerCase());


    if (!podcast){
        return <div>Podcast not found</div>
    }

    document.title = `${podcast.title}`;

    return(
        <div className='podcast-detail-container' style={{backgroundImage: `url(${podcast.backgroundUrl})`}}>

            <div className='pd-content'> 
                <div className='content-text'>
                    <h1>{podcast.title}</h1>
                    <h2>Host: {podcast.host}</h2>
                    <p>{podcast.description}</p>
                    <div className='social-links'>
                        {podcast.socialLinks && (
                            <React.Fragment>
                                {podcast.socialLinks.youtube && (
                                    <a href={podcast.socialLinks.youtube} target='_blank' rel='noopener noreferrer'>
                                        <FontAwesomeIcon icon={faYoutube}/>
                                    </a>
                                )}
                                {podcast.socialLinks.spotify && (
                                    <a href={podcast.socialLinks.spotify} target='_blank' rel='noopener noreferrer'>
                                        <FontAwesomeIcon icon={faSpotify}/>
                                    </a>
                                )}
                                {podcast.socialLinks.apple && (
                                    <a href={podcast.socialLinks.apple} target='_blank' rel='noopener noreferrer'>
                                        <FontAwesomeIcon icon={faApple}/>
                                    </a>
                                )}
                                {podcast.socialLinks.instagram && (
                                    <a href={podcast.socialLinks.instagram} target='_blank' rel='noopener noreferrer'>
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                )}
                                {podcast.socialLinks.tiktok && (
                                    <a href={podcast.socialLinks.tiktok} target='_blank' rel='noopener noreferrer'>
                                        <FontAwesomeIcon icon={faTiktok}/>
                                    </a>
                                )}
                                {podcast.socialLinks.facebook && (
                                    <a href={podcast.socialLinks.facebook} target='_blank' rel='noopener noreferrer'>
                                        <FontAwesomeIcon icon={faFacebook}/>
                                    </a>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    <div className='content-tags'>
                        {podcast.tags.map(tag => (
                            <span key={tag}>{tag}</span>
                        ))}
                    </div>
                </div>
                <img src={podcast.imageUrl} alt={podcast.title}/>
            </div>
        </div>
    );
};

export default PodcastDetail;