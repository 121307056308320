import React from 'react';
import PropTypes from 'prop-types'

const PhotoCard = ({ src, alt }) => {
    return (
        <div>
            <img src={src} alt={alt}/>
        </div>
    );
};

PhotoCard.prototype = { 
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
};

export default PhotoCard