/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styling/header.css';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 0){
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
            <div className='logo'>
                <Link to='/'>
                    <img src='/assets/images/itsleri-studios-logo.png'/>
                </Link>
            </div>

            <nav className={`nav ${isOpen ? 'open' : ''}`}>
                <ul>
                    <li><Link to='/' onClick={() => setIsOpen(false)}>Home</Link></li>
                    <li><Link to='/podcasts' onClick={() => setIsOpen(false)}>Podcasts</Link></li>
                    {/* <li><Link to='/shows-movies' onClick={() => setIsOpen(false)}>Shows/Movies</Link></li>
                    <li><Link to='/campaigns' onClick={() => setIsOpen(false)}>Campaigns</Link></li> */}
                    <li><Link to='/photography' onClick={() => setIsOpen(false)}>Photography</Link></li>
                    <li><Link to='/about-us' onClick={() => setIsOpen(false)}>About Us</Link></li>
                </ul>
            </nav>
            <div className='hamburger' onClick={toggleMenu}>
                <div className={`bar ${isOpen ? 'open' : ''}`}></div>
                <div className={`bar ${isOpen ? 'open' : ''}`}></div>
                <div className={`bar ${isOpen ? 'open' : ''}`}></div>
            </div>
        </header>
    );
};

export default Header;