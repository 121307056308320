import React from 'react';
import { Link } from 'react-router-dom';
import '../styling/podcast.css';

const podcasts = [
  {id: 1, title: 'Proverbial Minds', imageUrl: '/assets/images/proverbial-minds.png'},
  {id: 2, title: 'Ask Aunty Dupe', imageUrl: '/assets/images/ask-aunty-dupe.png'},
]

const Podcast = () => {
  return(
    <div className='podcast-container'>
      {
        podcasts.map(podcast => (
          <div key={podcast.id} className='podcast-item'>
            <div className='image-container'>
              <Link to={`/podcasts/${podcast.title.replace(/\s+/g, '-').toLowerCase()}`}>
                <img src={podcast.imageUrl} alt={podcast.title} />
                <div className='overlay'></div>
              </Link>
            </div>
          </div>
        ))
      }
    </div>
  );
}
  
export default Podcast;